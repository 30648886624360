<template>
  <div id="app">
    <div :style="showTabBar ? 'paddingBottom: 60px' : ''">
      <router-view />
    </div>

    <van-tabbar
      v-if="showTabBar"
      v-model="active"
      :border="false"
      active-color="#333"
      inactive-color="#666"
    >
      <van-tabbar-item
        v-for="tab in tabBars"
        :key="tab.route"
        replace
        :name="tab.path"
        :to="{ name: tab.route }"
      >
        <span>{{ tab.label }}</span>
        <template #icon="props">
          <img :src="props.active ? tab.activeIcon : tab.icon">
        </template>
      </van-tabbar-item>
    </van-tabbar>

    <login-tips :show="showLoginTips" @submit="showLoginTips = false" @close="showLoginTips = false" />

    <!-- 1=警告提示 2=系统封禁提示 3=手动封禁提示 5=购课系统封禁 6=购课人工封禁 7=购课警告 -->
    <account-tips
      v-if="[1, 7].indexOf(userBan.type) > -1"
      :show="[1, 7].indexOf(userBan.type) > -1"
      :tips="dialogTips[userBan.type]"
    />
    <account-ban-tips
      v-else-if="[2, 3, 5, 6, 8].indexOf(userBan.type) > -1"
      :show="[2, 3, 5, 6, 8].indexOf(userBan.type) > -1"
      :is-auto="[2, 5].indexOf(userBan.type) > -1"
      :tips="dialogTips[userBan.type]"
      :type="userBan.type"
    />
    <tutor-agreement-dialog
      v-else-if="showTutorAgreement"
      :show="showTutorAgreement"
      @agree="onAgree"
      @error-disappear="onErrorDisappear"
    />
    <tutor-signature-dialog
      v-else-if="showTutorSignature"
      :show="showTutorSignature"
      @go-back="onGoBack"
      @submit="onSubmit"
    />
    <base-tips
      :show="showSuccessTips"
      text="您的院校更换申请已经通过，快去购课吧！"
      show-cancel-btn
      @close="showSuccessTips = false"
      @submit="onSuccessSubmit"
    />
    <base-tips
      :show="showFailTips"
      text="很抱歉，您的院校更换申请没有通过，请联系小助手咨询详情～"
      @close="showFailTips = false"
      @submit="onFailSubmit"
    />

    <!-- <SixYearCouponModal /> -->
  </div>
</template>

<script>
import { $on, $off, $once, $emit } from '@/utils/gogocodeTransfer'
import { mapActions, mapGetters } from 'vuex'
import localforage from 'localforage'
import WeChatShareMixin from '@mixins/WeChatShareMixin'
import { isWechatBrowser, checkQrcodeStatus } from '@utils/helpers'
// import LoginTips from '@components/dialogs/LoginTips'
import AccountTips from './components/dialogs/AccountTips'
import AccountBanTips from './components/dialogs/AccountBanTips'
import TutorAgreementDialog from './components/dialogs/TutorAgreement'
import TutorSignatureDialog from './components/dialogs/TutorSignature'
import {
  getFingerTip,
  checkUserUniversityApproval,
  tutorReadCriterion
} from '@api'
import DialogTips from '@utils/dialog-tips'
import BaseTips from '@components/dialogs/BaseTips'
import SixYearCouponModal from '@/components/dialogs/SixYearCouponModal'
import { resolveStorageModePC } from '@/utils/device'

export default {
  name: 'AppLayout',

  components: {
    AccountTips,
    AccountBanTips,
    TutorAgreementDialog,
    TutorSignatureDialog,
    // LoginTips,
    BaseTips,
    // SixYearCouponModal,
  },

  mixins: [WeChatShareMixin],

  beforeRouteUpdate (to, form, next) {
    this.showTabBar = false

    if (
      this.tabBars.map((i) => i.path).indexOf(window.location.pathname) >= 0
    ) {
      this.showTabBar = true
      this.active = to.path || '/'
    }

    next()
  },

  data: (ctx) => ({
    approvalPath: '/courses',
    showSuccessTips: false,
    showFailTips: false,
    showLoginTips: false,
    showTutorAgreement: false,
    showTutorSignature: false,
    showTabBar: false,
    active: '/',
    tabBars: [
      {
        label: '首页',
        path: '/',
        route: 'home',
        icon: ctx.requireUrl('/icons/home.png'),
        activeIcon: ctx.requireUrl('/icons/home-selected.png')
      },
      {
        label: '公开课',
        path: '/courses',
        route: 'courses.index',
        icon: ctx.requireUrl('/icons/classes.png'),
        activeIcon: ctx.requireUrl('/icons/classes-selected.png')
      },
      // {
      //   label: 'HD Chat',
      //   path: '/chat',
      //   route: 'chat.index',
      //   icon: ctx.requireUrl('/icons/chat.svg'),
      //   activeIcon: ctx.requireUrl('/icons/chat-selected.svg')
      // },
      {
        label: '学习中心',
        path: '/user/courses',
        route: 'study.center',
        icon: ctx.requireUrl('/icons/courses.png'),
        activeIcon: ctx.requireUrl('/icons/courses-selected.png')
      },
      {
        label: '我的',
        path: '/user',
        route: 'user.index',
        icon: ctx.requireUrl('/icons/user.png'),
        activeIcon: ctx.requireUrl('/icons/user-selected.png')
      }
    ],
    share: {
      isSpecial: true,
      title: 'HD Education',
      desc: '快加入我们吧！',
      link: `https://${window.location.host}`,
      image: `https://${window.location.host}/logo.jpg`
    },
    dialogTips: DialogTips
  }),

  computed: {
    ...mapGetters('auth', {
      hasLogged: 'hasLogged',
      userBan: 'userBan',
      user: 'currentUser',
      tutorType: 'tutorType'
    }),
    isTypeExperience () {
      const from = this.$route.query?.from || ''
      const fromQuery = '?' + from.split('?')[1]
      const fromQueryType = (new URLSearchParams(fromQuery)).get('type')

      const queryType = new URLSearchParams(location.search).get('type')

      return [fromQueryType, queryType].includes('experience')
    }
  },

  watch: {
    $route (val) {
      this.showTabBar = false

      if (
        this.tabBars.map((i) => i.path).indexOf(window.location.pathname) >= 0
      ) {
        this.showTabBar = true
        this.active = val.path || '/'
      }
    },

    async hasLogged (val) {
      if (val && !!this.$route.query.code) {
        const { data } = await this.$http.get('get-user-abnormal-ip-log')

        if (data.is_need_remind && this.$route.query.code) {
          this.showLoginTips = true
        }
      }

      const {
        data: { data: banObj }
      } = await getFingerTip()
      await this.setUserBan(Object.assign({}, this.userBan, banObj))

      const { data } = await checkUserUniversityApproval()

      if (data.type) {
        switch (data.type) {
          case 1:
            this.showSuccessTips = true
            if (![0, 4].includes(data.page_type)) {
              this.approvalPath = data.page_detail
            }
            break
          case 2:
            this.showFailTips = true
            break
        }
      }
    },

    user () {
      this.onGetTutorReadCriterionStatus()
    }
  },

  async mounted () {
    if (
      this.tabBars.map((i) => i.path).indexOf(window.location.pathname) >= 0
    ) {
      this.showTabBar = true
      this.active = window.location.pathname || '/'
    }

    if (
      window.location.pathname.search(/\/courses\//) < 0 &&
      window.location.pathname.search(/\/package\//) < 0 &&
      window.location.pathname.search(/guide/) < 0 &&
      window.location.pathname.search(/rule/) < 0
    ) {
      if (isWechatBrowser()) {
        this.initSdkConfig()
        // eslint-disable-next-line no-undef
        wx.ready(() => {
          this.onSetMenu()
        })
      }
    }
    const errcode = this.getParam('errcode')
    const isMerge = this.getParam('isMerge')
    if (errcode && errcode !== '0' && !isMerge) {
      this.$dialog
        .alert({
          title: decodeURIComponent(this.getParam('message')),
          confirmButtonText: '我知道了',
          confirmButtonColor: '#FFD811'
        })
        .then(() => {
          this.$router.push('/user')
        })
    }

    $on(this.$root, 'buyFreeCourseSuccess', this.onBuyFreeCourseSuccess)

    ;['unload'].forEach((eventName) => {
      const { clear } = resolveStorageModePC()
      window.addEventListener(eventName, clear)
    })

    const flagTemp = await localforage.getItem('auth.flag')
    checkQrcodeStatus(flagTemp)
  },

  methods: {
    ...mapActions('auth', {
      setUserBan: 'setUserBan'
    }),

    onSuccessSubmit () {
      this.showSuccessTips = false
      this.$router.push(this.approvalPath)
    },

    onFailSubmit () {
      this.showFailTips = false
      this.$router.push('/user/information')
    },

    getParam (name) {
      const query = window.location.search.substring(1)
      const vars = query.split('&')
      for (let i = 0; i < vars.length; i++) {
        const pair = vars[i].split('=')
        if (pair[0] === name) {
          return decodeURIComponent(pair[1])
        }
      }
      return false
    },

    onGetTutorReadCriterionStatus () {
      if (this.isTypeExperience) { // AI 测评体验跳过
        return
      }
      if (this.user && this.user.is_tutor > 0 && (this.tutorType?.Class || this.tutorType?.OneToOne)) {
        this.showTutorAgreement = Boolean(
          !this.user.tutor_read_criterion_status
        )
      }
    },

    onAgree () {
      this.showTutorAgreement = false
      this.showTutorSignature = true
    },

    onErrorDisappear () {
      this.onGetTutorReadCriterionStatus()
    },

    onGoBack () {
      this.showTutorSignature = false
      this.showTutorAgreement = true
    },

    async onSubmit () {
      await tutorReadCriterion()
      this.showTutorSignature = false
      this.$toast.success('提交成功')
    },

    async onBuyFreeCourseSuccess () {
      const {
        data: { data: banObj }
      } = await getFingerTip()
      await this.setUserBan(Object.assign({}, this.userBan, banObj))
    }
  }
}
</script>

<style lang="scss" scoped>
.van-tabbar {
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.07);
  max-width: var(--app-max-width);
  margin: 0 auto;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.van-tabbar--fixed {
  right: 0;
}
.van-tabbar-item {
  font-size: 11px;
}
.van-tabbar-item__icon img {
  height: 21px;
}
</style>
