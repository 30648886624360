import store from '@/store'

export default (http: any) => {
  http.interceptors.request.use(
    (config: any) => {
      return config
    },
    (error: any) => {
      return Promise.reject(error)
    }
  )

  http.interceptors.response.use(
    (response: any) => {
      return response
    },
    async (error: any) => {
      if (error.response) {
        const urlParams = error.response.config.url.split('/')

        if ((error.response && error.response.status === 401 && ['login', 'register', 'quick-login'].indexOf(urlParams[urlParams.length - 1]) < 0) && import.meta.env.VITE_APP_ENV !== 'local') {
          await store.dispatch('auth/logout')
        }
      }

      return Promise.reject(error)
    }
  )
}
